import React, { useEffect, useState } from 'react'
import { OptionInterface } from '@src/interfaces/selectors'
import { Skeleton, StatusWidget } from '@revolut/ui-kit'
import { InterviewFeedbackInterface } from '@src/interfaces/interviewTool'
import {
  getInterviewScorecardTemplate,
  getInterviewScorecardTemplatePreview,
} from '@src/api/interviewScorecardTemplate'
import HeadCard from '@src/pages/Forms/InterviewScorecardTemplate/Preview/components/HeadCard'
import { InterviewFeedbackFormBody } from '@src/features/InterviewFeedbackForm/InterviewFeedbackFormV2'
import { useShowStatusPopup } from '@src/utils/useShowStatusPopup'
import { getStringMessageFromError } from '@src/store/notifications/actions'
import { AddSkills } from '@src/features/JobPostingFlow/HiringProcess/Scorecard/AddSkills'
import Form from '@src/features/Form/Form'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { InterviewScorecardTemplateInterface } from '@src/interfaces/interviewScorecardTemplates'
import { jobPostingScorecardTemplateFormRequests } from '@src/api/jobPosting'

interface ScorecardProps {
  scorecard?: OptionInterface
}

export const ScorecardForm = () => {
  const { values } = useLapeContext<InterviewScorecardTemplateInterface>()
  const [loading, setLoading] = useState(true)
  const [interviewScorecardPreview, setInterviewScorecardPreview] =
    useState<InterviewFeedbackInterface>()
  const showStatusPopup = useShowStatusPopup()
  const fetchPreview = async () => {
    try {
      setLoading(true)
      if (values?.id) {
        // TODO: PERF-6204 change previews from scorecard_template
        // to job_posting_scorecard, requires backend
        const template = await getInterviewScorecardTemplate(values.id)
        const preview = await getInterviewScorecardTemplatePreview(
          template.data,
          template.data.id,
        )
        setInterviewScorecardPreview(preview.data)
      }
    } catch (e) {
      showStatusPopup({
        status: 'error',
        title: 'Couldn’t get the scorecard',
        description: getStringMessageFromError(e),
      })
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchPreview()
  }, [])

  if (loading) {
    return <Skeleton />
  }

  if (!interviewScorecardPreview) {
    return (
      <StatusWidget>
        <StatusWidget.Image
          image={{
            default: 'https://assets.revolut.com/assets/3d-images-v2/3D086.png',
            '2x': 'https://assets.revolut.com/assets/3d-images-v2/3D086@2x.png',
            '3x': 'https://assets.revolut.com/assets/3d-images-v2/3D086@3x.png',
          }}
        />
        <StatusWidget.Title>This stage has no evaluation scorecard</StatusWidget.Title>
      </StatusWidget>
    )
  }

  return (
    <>
      <AddSkills
        interviewScorecardPreview={interviewScorecardPreview}
        loading={loading}
      />
      <HeadCard data={interviewScorecardPreview.scorecard.interview_objective} />
      <InterviewFeedbackFormBody
        showSkills={false}
        stageType={interviewScorecardPreview.stage_type?.id}
        values={interviewScorecardPreview}
      />
    </>
  )
}

export const Scorecard = ({ scorecard }: ScorecardProps) => {
  return (
    <Form
      api={jobPostingScorecardTemplateFormRequests}
      disableLocalStorageCaching
      forceParams={{ id: scorecard?.id ? String(scorecard.id) : undefined }}
    >
      <ScorecardForm />
    </Form>
  )
}

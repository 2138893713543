import React from 'react'
import {
  Box,
  DragAndDrop,
  HStack,
  Icon,
  IconButton,
  Item,
  Tag,
  TagBar,
  Token,
} from '@revolut/ui-kit'
import { HiringProcessInterface } from '@src/interfaces/hiringProccess'
import { deleteJobPostingHiringProcessStage } from '@src/api/jobPosting'
import { useConfirmationDialog } from '@src/features/Popups/ConfirmationDialogProvider'
import { useShowStatusPopup } from '@src/utils/useShowStatusPopup'
import { getMessageFromApiError } from '@src/store/notifications/actions'

interface Props {
  disabled: boolean
  sortable?: DragAndDrop.DefaultSortableItemState<{}>
  stage: HiringProcessInterface
  onAfterDelete: VoidFunction
  onClick: VoidFunction
  onEdit: VoidFunction
}

export const HiringStage = ({
  disabled,
  sortable,
  stage,
  onAfterDelete,
  onClick,
  onEdit,
}: Props) => {
  const confirmationDialog = useConfirmationDialog()
  const showStatusPopup = useShowStatusPopup()

  const canDelete = !stage.specialisation_hiring_stage?.is_mandatory
  const canEdit = !stage.specialisation_hiring_stage
  const hasActions = canDelete || canEdit

  const handleDelete = () => {
    const { id: jobPostingId } = stage.job_posting || {}
    if (jobPostingId) {
      confirmationDialog.show({
        body: 'Are you sure you want to delete this hiring stage?',
        noMessage: 'Cancel',
        yesMessage: 'Confirm',
        onConfirm: async () => {
          try {
            await deleteJobPostingHiringProcessStage(jobPostingId, stage.id)
            showStatusPopup({
              title: 'Success deleting hiring stage',
              onClose: onAfterDelete,
            })
          } catch (e) {
            showStatusPopup({
              title: 'There was a error deleting hiring stage',
              status: 'error',
              description: getMessageFromApiError(e),
            })
          }
        },
      })
    }
  }
  return (
    <Item
      use={disabled ? undefined : 'label'}
      variant={hasActions ? undefined : 'disclosure'}
      aria-disabled={disabled}
      onClick={event => {
        event.preventDefault()
        if (!disabled) {
          onClick()
        }
      }}
      ref={sortable?.setNodeRef}
      style={
        sortable
          ? {
              transform: sortable.transform
                ? `translate3d(${sortable.transform.x}px, ${sortable.transform.y}px, 0)`
                : undefined,
              transition: sortable.transition || 'none',
              opacity: sortable.isDragging ? 0 : undefined,
            }
          : undefined
      }
    >
      <Item.Prefix>
        <Icon
          ref={sortable?.setNodeRef}
          {...sortable?.attributes}
          {...sortable?.listeners}
          name="Drag"
          color={Token.color.greyTone50}
          data-testid={`drag_${stage.title}`}
        />
      </Item.Prefix>
      <Item.Content>
        <Item.Title>{stage.title}</Item.Title>
        {stage.notes && <Item.Description>{stage.notes}</Item.Description>}
        {!stage.specialisation_hiring_stage && (
          <Box mt="s-16">
            <TagBar>
              <Tag color={Token.color.warning} useIcon="Flag" variant="faded">
                Customised
              </Tag>
            </TagBar>
          </Box>
        )}
      </Item.Content>
      {hasActions && (
        <Item.Side>
          <HStack gap="s-12">
            {canEdit && (
              <IconButton
                aria-label={`Edit ${stage.title} hiring stage`}
                color={Token.color.greyTone20}
                useIcon="Pencil"
                onClick={e => {
                  e.stopPropagation()
                  onEdit()
                }}
              />
            )}
            {canDelete && (
              <IconButton
                aria-label={`Delete ${stage.title} hiring stage`}
                color={Token.color.greyTone20}
                useIcon="Delete"
                onClick={e => {
                  e.stopPropagation()
                  handleDelete()
                }}
              />
            )}
          </HStack>
        </Item.Side>
      )}
    </Item>
  )
}

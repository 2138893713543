import React, { useMemo } from 'react'
import { useGetCalibrationFlags } from '@src/api/performanceSettings'
import { CalibrationInterface } from '@src/interfaces/performance'
import Tooltip from '@src/components/Tooltip/Tooltip'
import {
  Badge,
  Token,
  Text,
  VStack,
  HStack,
  Icon,
  Flex,
  Spacer,
  IconName,
} from '@revolut/ui-kit'
import pluralize from 'pluralize'
import { CalibrationFlag } from '@src/interfaces/settings'

interface Props {
  data: CalibrationInterface
}

const getRecommendationContent = (
  recommendation: CalibrationFlag['recommendation'],
): { text: string; icon: IconName } => {
  switch (recommendation) {
    case 'grade_down':
      return { text: 'Grade down', icon: 'ArrowDown' }
    case 'grade_up':
      return { text: 'Grade up', icon: 'ArrowUp' }
    default:
      return { text: '', icon: 'Minus' }
  }
}

const getFlagIcon = (flag: CalibrationFlag): IconName => {
  // TODO Add More icons when they are provided by designers / PO
  // https://revolut.atlassian.net/browse/REVC-7803
  switch (flag.name) {
    case 'Line Manager Leniency / Accuracy':
    case 'Functional Manager Leniency / Accuracy':
    case 'Grade not supported by deliverables':
    case 'Grade not supported by bar raiser questions':
      return '16/ExclamationMarkSign'
    case 'Consistent Performing':
    case 'Consistent Exceeding+':
    case 'Consistent Developing':
      return 'StarSemi'
    case 'QoQ Decline':
      return 'WealthDown'
    case 'First performance review':
    case 'Direct exit':
    case 'QoQ Improvement':
    default:
      return '16/ExclamationMarkSign'
  }
}

export const CalibrationFlagsCell = ({ data }: Props) => {
  const { data: { results: calibrationFlags = [] } = {} } = useGetCalibrationFlags()

  const flags = useMemo(() => {
    return (
      data.calibration_flags
        ?.map(flag => calibrationFlags.find(({ name }) => name === flag))
        .filter(Boolean) || []
    )
  }, [data.calibration_flags, calibrationFlags])

  if (!data.calibration_flags?.length) {
    return null
  }

  return (
    <Tooltip
      placement="left"
      backgroundColor={Token.color.popoverBackground}
      body={
        <VStack p="s-12" width={400} color={Token.color.greyTone50}>
          <HStack gap="s-8">
            <Text variant="emphasis2" color={Token.color.foreground}>
              Flags
            </Text>
            <Spacer />
            <Text variant="emphasis2" textAlign="end">
              Recommendation
            </Text>
          </HStack>
          {flags.map(flag => (
            <HStack key={flag.id} gap="s-4">
              <HStack align="center" gap="s-2">
                <Icon color={Token.color.accent} name={getFlagIcon(flag)} size={12} />
                <Text variant="body3">{flag.description}</Text>
              </HStack>
              <Spacer />
              <Flex justifyContent="flex-end" alignItems="center" gap="s-2">
                <Text variant="body3" whiteSpace="nowrap">
                  {getRecommendationContent(flag.recommendation).text}
                </Text>
                <Icon
                  name={getRecommendationContent(flag.recommendation).icon}
                  size={12}
                />
              </Flex>
            </HStack>
          ))}
        </VStack>
      }
    >
      <Badge useIcon="Flag" bg={Token.color.greyTone8} color={Token.color.greyTone50}>
        <Text px="s-4">
          {pluralize('Flag', data.calibration_flags?.length || 0, true)}
        </Text>
      </Badge>
    </Tooltip>
  )
}

import React, { useState } from 'react'
import FilterButtonCheckboxSelect from '@src/components/FilterButtonCheckboxSelect/FilterButtonCheckboxSelect'
import { useGetSelectors } from '@src/api/selectors'
import { selectorKeys } from '@src/constants/api'
import { BaseOptionId, OptionInterface } from '@src/interfaces/selectors'
import { HStack } from '@revolut/ui-kit'

interface SkillFilterProps {
  onChange: (newFilter: OptionInterface<BaseOptionId>[] | undefined) => void
}

export const SkillFilter = ({ onChange }: SkillFilterProps) => {
  const { data: functions } = useGetSelectors(selectorKeys.functions)
  const [selectedFunctions, setSelectedFunctions] = useState<OptionInterface[]>([])
  return (
    <HStack gap="s-16">
      <FilterButtonCheckboxSelect
        label="Function"
        searchable
        width={300}
        options={functions || []}
        value={selectedFunctions}
        onChange={newSelectedFunctions => {
          setSelectedFunctions(newSelectedFunctions || [])
          onChange(newSelectedFunctions)
        }}
      />
    </HStack>
  )
}

import { FieldOptions, IdAndName, Statuses } from '@src/interfaces/index'
import { EmployeeOptionInterface } from '@src/interfaces/employees'
import { ScheduleInterface } from '@src/interfaces/schedule'
import { IconName } from '@revolut/ui-kit'
import { AudienceInterface } from '@src/components/AddAudienceSidebar/interfaces'

export interface EngagementQuestionVoicesInterface {
  id: number
  question: EngagementQuestionInterface
}

export interface EngagementSurveySettingsInterface {
  id: number
  min_number_of_responses: number
  min_group_size: number
}

export interface EngagementDriverInterface {
  id: number
  name: string
  description: string | null
  file_url: string
  image: string
  status: IdAndName<Statuses>
  owner: EmployeeOptionInterface
  field_options: FieldOptions
  creation_date_time?: string
  update_date_time?: string
  active_questions_count: number
  questions: Array<
    Pick<
      EngagementQuestionInterface,
      'id' | 'title' | 'question_text' | 'status' | 'last_answered_on'
    >
  >
}

export interface EngagementAnswerInterface {
  id: number
  question: { id: number }
  answer_score: number | null
  question_queue_item_id: number | null
  answer_text?: string
  categories?: {
    names: [string] // yes, just one string
  }
  is_test?: boolean
}

export type EngagementQuestionTypeId = 'open_ended' | 'rating' | 'nps'

export interface EngagementQuestionInterface {
  id: number
  title: string
  question_text: string
  description?: string
  comment: IdAndName<'no_comment' | 'optional' | 'mandatory'>
  owner: EmployeeOptionInterface
  driver: EngagementDriverInterface
  creation_date_time?: string
  updated_date_time?: string
  status: IdAndName<Statuses>
  field_options: FieldOptions
  answered_the_question?: boolean
  driver_active_questions_count: number
  last_answered_on: string | null
  in_bank?: boolean | null
  type: IdAndName<EngagementQuestionTypeId> | null
  categories?: {
    names: string[]
  }
}

export interface EngagementSurveyDetailsInterface {
  id: number
  title: string
  instructions: string
  completed_questions: number
  total_questions: number
}

export interface EngagementSurveyQuestionInterface {
  id?: number
  question: EngagementQuestionInterface
  order: number
}

export interface EngagementSurveyInterface extends ScheduleInterface {
  id: number
  name: string
  title: string
  instructions: string
  round_status?: IdAndName<
    | Statuses.inactive
    | Statuses.planned
    | Statuses.active
    | Statuses.running
    | Statuses.terminated
    | Statuses.template
    | Statuses.archived
  >
  subtitle?: string
  key_values?: string[]
  is_recommended?: boolean
  status: IdAndName<
    | Statuses.scheduled
    | Statuses.completed
    | Statuses.active
    | Statuses.inactive
    | Statuses.archived
    | Statuses.terminated
    | Statuses.hidden_draft
  >
  sending_date: string
  closing_date: string
  owner: EmployeeOptionInterface
  audiences: AudienceInterface[]
  audience_size: number
  participation_rate: number
  update_date_time: string
  updated_by: EmployeeOptionInterface
  num_questions: number
  survey_questions: EngagementSurveyQuestionInterface[]
  response_deadline_days: number
  min_number_of_responses: number
  display_name: string
  description: string
  campaign: IdAndName<string>
  is_published: boolean
  email_notification_enabled: boolean
  slack_notification_enabled: boolean
  system_notification_enabled: boolean
  field_options: FieldOptions
  icon: IconName | null
  avatar: string | null
  creation_date_time: string
}

export interface EngagementSurveyRoundInterface {
  audience_size: number
  response_deadline_days: number
  id: number
  sent_on: string | null
  closing_date: string | null
  sent_by: EmployeeOptionInterface | null
  total_questions: number
  participation_rate: number | null
  status: IdAndName<'upcoming' | 'ongoing' | 'completed' | 'terminated' | 'cancelled'>
  response_count: number
}

export interface EngagementSurveyStatsInterface {
  total: number
  active: number
  inactive: number
  archived: number
  planned: number
  running: number
}

export enum EngagementResultsScope {
  Departments = 'departments',
  Teams = 'teams',
  Roles = 'positions',
  Functions = 'functions',
  Specialisations = 'specialisations',
  Employees = 'lineManagers',
  Company = 'company',
}

export enum EngagementResultsEntities {
  Drivers = 'drivers',
  Questions = 'questions',
  Feedback = 'feedback',
}

export interface EngagementResultsAverageScoreInterface {
  promoters: number
  passives: number
  detractors: number
  unanswered: number
}

export type EngagementResultsOpenEndedDistribution = Record<string, number>

export type EngagementResultsScoresInterface = Record<1 | 2 | 3 | 4 | 5, number>

export type BenchmarkId =
  | 'n/a'
  | 'top_5'
  | 'top_10'
  | 'top_25'
  | 'middle'
  | 'bottom_25'
  | 'bottom_10'
  | 'bottom_5'

type QuestionInResult = IdAndName & {
  question_text: string
  type: IdAndName<'open_ended' | 'rating'>
}
type DriverInResult = IdAndName & { description: string }

export interface EngagementResultInterface
  extends EngagementResultsAverageScoreInterface {
  id: number
  driver?: DriverInResult
  question?: QuestionInResult
  survey: EngagementSurveyInterface
  average_score: number
  trend_average_score: number | null
  nps_score: number
  trend_nps_score: number | null
  scores_distribution: EngagementResultsScoresInterface
  open_ended_distribution: EngagementResultsOpenEndedDistribution
  last_sent_on: string
  participation_rate: number
  number_of_answers: number
  number_of_answers_with_comment: number
  benchmark: IdAndName<BenchmarkId> | null
  can_show_details: boolean
  unanswered: number
}

export interface EngagementResultV2Interface
  extends EngagementResultsAverageScoreInterface {
  id: number
  average_score: number
  can_show_results: boolean
  detractors: number
  detractors_rate: number
  driver: string
  from_date: string
  name: string
  passives: number
  passives_rate: number
  promoters: number
  promoters_rate: number
  respondents: number
  to_date: string
  type: string
}

export interface EngagementDriverChartDatum extends EngagementResultInterface {
  driver: DriverInResult
}

export interface EngagementQuestionChartDatum extends EngagementResultInterface {
  driver: DriverInResult
  question: QuestionInResult
}

export interface SurveyParticipationStats {
  audience_size: number
  participation_rate: number
}

export interface DriverResultStatsInterface {
  total_headcount: number
  drivers: number
  participation_rate: number
  nps_score: number
  can_show_details: boolean
}

export interface QuestionResultStatsInterface {
  total_headcount: number
  questions: number
  drivers: number
  participation_rate: number
  nps_score: number
  can_show_details: boolean
}

export type EngagementResultsComment = {
  id: number
  answer_score: number | null
  answer_text: string
  creation_date_time: string
  driver: IdAndName
  question: {
    id: number
    question_text: string
  }
  categories: {
    names: string[]
  }
}

export type EngagementResultsCommentsStats = {
  can_show_details: boolean
}

export interface EngagementStartSurveyInterface {
  id: number
  send_on_time: string
  send_on_timezone: {
    id?: string
    name?: string
    offset?: string
  }
}

export interface EngagementResultsHeatmapScoreInterface {
  anonymized: boolean
  name: string
  average_score: number
  max_value: number
  min_value: number
  trend_average_score: null
}

export interface EngagementResultsHeatmapRowInterface {
  id: number
  name: string
  scores: EngagementResultsHeatmapScoreInterface[]
}

export interface EngagementSurveyAnalyticsInterface {
  id: number
  audience_size: number
  average_score: number
  from_date: string
  name: string
  response_rate: number
  responses: number
  status: string
  to_date: string
}

export type GroupByOption = IdAndName<
  'role' | 'department' | 'function' | 'location' | 'country' | 'team'
>
export type GroupByOptionItem = IdAndName<string>

export interface EngagementResultsItemDetailsInterface {
  id: number
  anonymized: boolean
  average_score: number
  detractors: number
  detractors_rate: number
  driver: string
  name: string
  passives: number
  passives_rate: number
  promoters: number
  promoters_rate: number
  respondents: number
  type: string
}

export interface EngagementResultsItemTimeSeriesInterface {
  from_date: string
  to_date: string
  average_score: number
}

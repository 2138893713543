import { EmployeeInterface } from '@src/interfaces/employees'
import { PerformanceSelector, ReviewSummaryInterface } from '@src/interfaces/performance'
import React, { useState } from 'react'
import { TeamGoalsCardTable } from '@src/pages/Forms/EmployeePerformanceLayout/components/TeamGoalsCardTable'
import { EmployeeGoalsCardTable } from '@src/pages/Forms/EmployeePerformanceLayout/components/EmployeeGoalsCardTable'
import { ReviewCycleCategory } from '@src/interfaces/reviewCycles'
import { EmployeeKpisCardTable } from '@src/pages/Forms/EmployeePerformanceLayout/components/EmployeeKpisCardTable'
import { Icon, Token } from '@revolut/ui-kit'
import { useInitialFilters } from '@src/features/Goals/GoalsTab'
import Loader from '@src/components/CommonSC/Loader'
import { CollapsableTableWrapper } from '@src/pages/Forms/EmployeePerformanceLayout/components/summary/CollapsableTableWrapper'
import { CalculatedDeliverablesRating } from '../Summary/useCalculatedDeliverablesRating'
import { useGoalsVisibilityBySummary } from '@src/features/Goals/useGoalsVisibilityBySummary'

export const NoReviewsGoalsCollapsable = ({
  summary,
  employee,
  selectedPeriod,
  calculatedDeliverablesRating,
}: {
  summary?: Pick<
    ReviewSummaryInterface,
    'team' | 'grade_label_mapping' | 'reviewed_employee_type'
  >
  employee: Pick<EmployeeInterface, 'id'>
  calculatedDeliverablesRating?: CalculatedDeliverablesRating | null
  selectedPeriod: PerformanceSelector
}) => {
  const [goalsStat, setGoalsStat] = useState<number>()
  const [goalsTableCount, setGoalsTableCount] = useState<number | undefined>()
  const [kpiTableCount, setKpiTableCount] = useState<number | undefined>()
  const initialFilters = useInitialFilters(selectedPeriod)
  const { isFetching, isIndividualGoalsVisible, isTeamGoalsVisible } =
    useGoalsVisibilityBySummary(summary, initialFilters ?? [])
  const team = summary?.team
  const gradesMap = summary?.grade_label_mapping

  if (!summary || !gradesMap) {
    return null
  }

  if (
    selectedPeriod.category === ReviewCycleCategory.Probation &&
    selectedPeriod.probation_reviews_deliverables_type === 'kpi'
  ) {
    return (
      <CollapsableTableWrapper
        icon={<Icon name="Target" color={Token.color.onAccent} size={24} />}
        headerTitle="Goals"
        headerStat={goalsStat}
        headerRating={calculatedDeliverablesRating?.rating}
        ratings={calculatedDeliverablesRating?.ratings}
        mapperFunc={calculatedDeliverablesRating?.mapper}
        gradesMap={calculatedDeliverablesRating?.gradesMap}
        count={kpiTableCount}
      >
        <EmployeeKpisCardTable
          isScrollable={false}
          setKpiTableCount={setKpiTableCount}
          gradesMap={gradesMap}
          employeeId={employee.id}
          cycle={selectedPeriod}
        />
      </CollapsableTableWrapper>
    )
  }
  return (
    <CollapsableTableWrapper
      icon={<Icon name="Target" color={Token.color.onAccent} size={24} />}
      headerTitle="Goals"
      headerStat={goalsStat}
      headerRating={calculatedDeliverablesRating?.rating}
      ratings={calculatedDeliverablesRating?.ratings}
      mapperFunc={calculatedDeliverablesRating?.mapper}
      gradesMap={calculatedDeliverablesRating?.gradesMap}
      completedReviews
      count={goalsTableCount}
    >
      {isFetching && <Loader />}
      {isIndividualGoalsVisible && !isFetching && (
        <EmployeeGoalsCardTable
          setGoalsTableCount={setGoalsTableCount}
          gradesMap={gradesMap}
          setGoalsStat={setGoalsStat}
          noWidget
          employeeId={employee.id}
          cycle={selectedPeriod}
        />
      )}
      {isTeamGoalsVisible && !isFetching && (
        <TeamGoalsCardTable
          setGoalsTableCount={setGoalsTableCount}
          showBanner
          isNewDesign
          isPerformanceBanner
          gradesMap={gradesMap}
          cycle={selectedPeriod}
          team={team}
          noWidget
        />
      )}
    </CollapsableTableWrapper>
  )
}

import React, { useMemo, useState } from 'react'
import {
  ActionButton,
  Avatar,
  Box,
  Item,
  ItemSkeleton,
  VStack,
  Widget,
} from '@revolut/ui-kit'
import { InterviewFeedbackInterface } from '@src/interfaces/interviewTool'
import { SkillPreview } from '@src/features/JobPostingFlow/HiringProcess/Scorecard/SkillPreview'
import { SkillSidebar } from '@src/features/JobPostingFlow/HiringProcess/Scorecard/SkillsSidebar'
import uniq from 'lodash/uniq'
import { isInterviewFeedbackSkillSection } from '@src/features/JobPostingFlow/HiringProcess/Scorecard/utils'

interface AddSkillsProps {
  interviewScorecardPreview?: InterviewFeedbackInterface
  loading: boolean
}

export const AddSkills = ({ interviewScorecardPreview, loading }: AddSkillsProps) => {
  const [openSkillsSidebar, setOpenSkillsSidebar] = useState(false)

  const { skillSections, skillIds } = useMemo(() => {
    const sections = (interviewScorecardPreview?.scorecard?.sections || []).filter(
      isInterviewFeedbackSkillSection,
    )
    return {
      skillSections: sections,
      skillIds: uniq(
        sections.flatMap(section => section.skills?.flatMap(skill => skill.id)),
      ),
    }
  }, [interviewScorecardPreview])

  if (loading) {
    return (
      <Widget mb="s-24">
        <ItemSkeleton />
      </Widget>
    )
  }

  return (
    <>
      <Widget mb="s-24">
        <VStack gap="s-16">
          <Item>
            <Item.Avatar>
              <Avatar useIcon="Palette" />
            </Item.Avatar>
            <Item.Content>
              <Item.Title>Skills</Item.Title>
            </Item.Content>
          </Item>
          {!!skillSections.length && (
            <VStack px="s-16" pb="s-16" gap="s-16">
              {skillSections.map(section => (
                <SkillPreview key={section.internal_data_id} section={section} />
              ))}
            </VStack>
          )}
          <Box px="s-16" pb="s-16">
            <ActionButton
              useIcon="Plus"
              onClick={() => setOpenSkillsSidebar(!openSkillsSidebar)}
            >
              Add skills
            </ActionButton>
          </Box>
        </VStack>
      </Widget>
      {openSkillsSidebar && (
        <SkillSidebar
          skillIds={skillIds}
          onClose={() => setOpenSkillsSidebar(!openSkillsSidebar)}
        />
      )}
    </>
  )
}

import React, { MouseEvent, useEffect, useMemo, useState } from 'react'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { Avatar, Box, InputGroup, Item, Subheader, Widget } from '@revolut/ui-kit'
import AutoStepper from '@components/Stepper/AutoStepper'
import NewStepperTitle from '@components/Stepper/NewStepperTitle'
import LapeNewInput from '@components/Inputs/LapeFields/LapeNewInput'
import { LookerModelAccessInterface } from '@src/interfaces/accessRequest'
import { selectorKeys } from '@src/constants/api'
import {
  InterviewScorecardEnumSectionIds,
  EnumSectionOptions,
  InterviewScorecardType,
  InterviewScorecardTemplateInterface,
  InterviewScorecardTypeInterface,
} from '@src/interfaces/interviewScorecardTemplates'
import { connect, useLape } from 'lape'
import { Grid } from '@components/CommonSC/Grid'
import { Plus } from '@revolut/icons'
import HiringScorecardSaveToDraftButton from '@src/pages/Forms/InterviewScorecardTemplate/Buttons/InterviewScorecardTemplateSaveToDraftButton'
import HiringScorecardsSubmitButton from '@src/pages/Forms/InterviewScorecardTemplate/Buttons/InterviewScorecardTemplateSubmitButton'
import { PageBody } from '@src/components/Page/PageBody'
import { PageActions } from '@src/components/Page/PageActions'
import Outcome from '@src/pages/Forms/InterviewScorecardTemplate/Sections/Outcome/Outcome'
import { SeniorityInterface } from '@src/interfaces/seniority'
import { useSelector } from 'react-redux'
import { selectUser } from '@src/store/auth/selectors'
import { EmployeeOptionInterface } from '@src/interfaces/employees'
import { getSelectors, useGetSelectors } from '@src/api/selectors'
import ScorecardTypeSelect from '@src/pages/Forms/InterviewScorecardTemplate/Preview/components/ScorecardTypeSelect'
import SkillSelector from '@src/pages/Forms/InterviewScorecardTemplate/Preview/components/SkillSelector'
import MissingSkillsWarning from '@src/pages/Forms/InterviewScorecardTemplate/Preview/components/MIssingSkillsWarning'
import { isLevelBasedScorecard, isPointBasedScorecard } from '@src/utils/interview'
import LapeRadioSelectInput from '@components/Inputs/LapeFields/LapeRadioSelectInput'
import LapeHTMLEditor from '@components/Inputs/LapeFields/LapeHTMLEditor'
import InterviewScorecardTemplateSection from '@src/pages/Forms/InterviewScorecardTemplate/InterviewScorecardTemplateForm/InterviewScorecardTemplateSection'
import { useGetHiringProcessSettings } from '@src/api/settings'
import LocationsQuestionSection from '@src/pages/Settings/Candidates/ScreenCallScorecards/components/LocationsQuestionSection'
import SalaryQuestionSection from '@src/pages/Settings/Candidates/ScreenCallScorecards/components/SalaryQuestionSection'
import { getHiringSectionIndexes } from '@src/pages/Settings/HiringProcess/utils'
import RightToWorkQuestionSection from '@src/pages/Settings/Candidates/ScreenCallScorecards/components/RightToWorkQuestionSection'
import { getPrefilledSection } from '@src/pages/Forms/InterviewScorecardTemplate/InterviewScorecardTemplateForm/utils'

export const validator = {}

const MAX_WIDTH = 904

type InterviewScorecardTemplateFormProps = {
  isCompact?: boolean
  onSubmit?: (
    result: InterviewScorecardTemplateInterface,
  ) => Promise<InterviewScorecardTemplateInterface> | void
  noPopup?: boolean
  renderActions?: (actions: JSX.Element) => JSX.Element
  submitLabel?: string
  onAfterSubmit?: (result: InterviewScorecardTemplateInterface) => void
}

const InterviewScorecardTemplateForm = ({
  noPopup,
  submitLabel,
  onAfterSubmit,
  isCompact,
  renderActions,
  onSubmit,
}: InterviewScorecardTemplateFormProps) => {
  const context = useLapeContext<InterviewScorecardTemplateInterface>()
  const { values, errors } = context
  const [editId, setEditId] = useState(0)
  const user = useSelector(selectUser)
  const { data: interviewScorecardTypes } =
    useGetSelectors<InterviewScorecardTypeInterface>(
      selectorKeys.interview_scorecard_template_types,
    )
  const { data: settings } = useGetHiringProcessSettings()
  const state = useLape({
    skillsLoading: false,
  })

  const scorecardType = values.scorecard_type?.id

  useEffect(() => {
    if (!values.owner && user) {
      values.owner = { id: user.id, full_name: user.full_name } as EmployeeOptionInterface
    }

    if (scorecardType === InterviewScorecardType.Delivery) {
      const delivery = { id: 'delivery', name: 'Delivery' }
      values.skills = [delivery]
      values.sections?.forEach(section => {
        section.skills = [delivery]
      })
    }

    fetchSeniorities()
  }, [])

  useEffect(() => {
    if (interviewScorecardTypes?.length && !values.scorecard_type) {
      onChangeScorecardType()
    }
  }, [interviewScorecardTypes])

  useEffect(() => {
    state.skillsLoading = true

    const skillsToBeAdded =
      values.skills?.filter(skill => {
        if (!values.sections?.length) {
          return true
        }

        return values.sections.every(
          section => !section.skills?.find(sectionSkill => sectionSkill.id === skill.id),
        )
      }) || []

    const promises = skillsToBeAdded.map(skill => getPrefilledSection(skill))

    Promise.allSettled(promises)
      .then(results => {
        results.forEach(result => {
          if (result.status === 'fulfilled') {
            values.sections = [...(values.sections || []), result.value]
          }
        })
      })
      .finally(() => {
        state.skillsLoading = false
      })
  }, [values.skills])

  const possibleSectionOptions = useMemo(() => {
    if (isPointBasedScorecard(scorecardType)) {
      return EnumSectionOptions.filter(
        item => item.id !== InterviewScorecardEnumSectionIds.Level,
      )
    }
    return EnumSectionOptions
  }, [scorecardType])

  const fetchSeniorities = async () => {
    // because default user doesn't have permissions to seniority API
    const response = await getSelectors(selectorKeys.seniority)
    const sortedSeniorities = (response.data.options as SeniorityInterface[]).sort(
      (a, b) => a.level - b.level,
    )
    values.seniorities_points = sortedSeniorities.map((s, id) => ({
      text: s.name,
      points: values.seniorities_points?.[id]?.points,
      seniority: s,
    }))
  }

  const handleAddSection = (e: MouseEvent<HTMLDivElement>) => {
    e.preventDefault()
    values.sections = [
      ...(values.sections || []),
      { section_type: possibleSectionOptions[0], questions: [] },
    ]

    setEditId(values.sections.length - 1)
  }

  const onChangeScorecardType = async (newType?: InterviewScorecardTypeInterface) => {
    let newScorecardType

    if (newType) {
      newScorecardType = { ...newType }
    } else if (interviewScorecardTypes) {
      newScorecardType = { ...interviewScorecardTypes[0] }
    } else {
      return
    }

    values.scorecard_type = newScorecardType
    values.skills =
      newScorecardType.id === InterviewScorecardType.Delivery
        ? [{ id: 'delivery', name: 'Delivery' }]
        : []
  }

  const { predefinedSectionsLength, globalSectionsLength } = getHiringSectionIndexes(
    values.stage_type?.id === 'screen_call',
    settings,
  )

  const renderScorecardDetails = () => {
    return (
      <>
        <Widget p="s-16">
          <InputGroup>
            <LapeNewInput name="name" label="Name" required />
            <InputGroup variant={isCompact ? 'vertical' : 'horizontal'}>
              <Box style={{ position: 'relative', flex: 1 }}>
                <ScorecardTypeSelect
                  options={interviewScorecardTypes}
                  onChange={newType => {
                    onChangeScorecardType(newType || undefined)
                  }}
                  value={values.scorecard_type}
                />
              </Box>
              <LapeRadioSelectInput<LookerModelAccessInterface>
                name="stage_type"
                label="Stage type"
                selector={selectorKeys.interview_scorecard_stage_types}
                clearable
                optional
              />
              <LapeRadioSelectInput<LookerModelAccessInterface>
                name="owner"
                label="Owner"
                selector={selectorKeys.employee}
              />
            </InputGroup>
            {isLevelBasedScorecard(scorecardType) && (
              <SkillSelector loading={state.skillsLoading} />
            )}
            <LapeHTMLEditor
              name="interview_objective"
              placeholder="Interview objective"
              required
              height={80}
            />
          </InputGroup>
        </Widget>
        <MissingSkillsWarning />
      </>
    )
  }

  const renderPredefinedSections = (title: React.ReactNode) => {
    if (!(predefinedSectionsLength || globalSectionsLength)) {
      return null
    }
    return (
      <>
        {title}
        <Grid gap={16}>
          {settings?.enable_location_collecting && (
            <LocationsQuestionSection optional={settings.is_location_optional} locked />
          )}
          {settings?.enable_compensation_collecting && (
            <SalaryQuestionSection optional={settings.is_compensation_optional} locked />
          )}
          {settings?.enable_right_to_work_collecting && (
            <RightToWorkQuestionSection
              optional={settings.is_right_to_work_optional}
              locked
            />
          )}
          {settings?.global_sections?.map((section, id) => {
            return (
              <InterviewScorecardTemplateSection
                key={id}
                isPreview
                id={id}
                editId={editId}
                setEditId={setEditId}
                section={section}
                onChange={val => {
                  values.sections![id] = val
                }}
                onDelete={() => {
                  values.sections = values.sections?.filter((_, i) => i !== id)
                }}
                onCopy={() => {
                  values.sections?.push({
                    ...values.sections[id],
                    questions: values.sections[id]?.questions?.map(question => ({
                      ...question,
                      id: undefined,
                    })),
                    id: undefined,
                  })
                }}
                onMove={direction => {
                  const offset = direction === 'down' ? 1 : -1
                  if (editId === id) {
                    setEditId(id + offset)
                  }
                  if (editId === id + offset) {
                    setEditId(id)
                  }
                }}
                sections={values.sections!}
                errors={errors?.sections?.[id]}
                skills={values.skills}
                scorecardType={scorecardType}
                sectionOptions={possibleSectionOptions}
                sectionsFieldName="global_sections"
                locked
                titleIndexNumber={id + predefinedSectionsLength + 1}
              />
            )
          })}
        </Grid>
      </>
    )
  }

  const renderScorecardSections = () => {
    return (
      <>
        <Grid gap={16}>
          {values.sections?.map((section, idx) => {
            const titleIndexNumber =
              globalSectionsLength + predefinedSectionsLength + idx + 1

            return (
              <InterviewScorecardTemplateSection
                key={idx}
                isPreview={editId !== idx}
                id={idx}
                editId={editId}
                setEditId={setEditId}
                section={section}
                onChange={val => {
                  values.sections![idx] = val
                }}
                onDelete={() => {
                  values.sections = values.sections?.filter((_, i) => i !== idx)
                }}
                onCopy={() => {
                  values.sections?.push({
                    ...values.sections[idx],
                    questions: values.sections[idx]?.questions?.map(question => ({
                      ...question,
                      id: undefined,
                    })),
                    id: undefined,
                  })
                }}
                onMove={direction => {
                  const offset = direction === 'down' ? 1 : -1
                  if (editId === idx) {
                    setEditId(idx + offset)
                  }
                  if (editId === idx + offset) {
                    setEditId(idx)
                  }
                }}
                sections={values.sections!}
                errors={errors?.sections?.[idx]}
                skills={values.skills}
                scorecardType={scorecardType}
                sectionOptions={possibleSectionOptions}
                sectionsFieldName="sections"
                titleIndexNumber={titleIndexNumber}
              />
            )
          })}
          <Item use="button" type="button" onClick={handleAddSection}>
            <Item.Avatar>
              <Avatar useIcon={Plus} />
            </Item.Avatar>
            <Item.Content color="primary">Add new section</Item.Content>
          </Item>
        </Grid>
        <Outcome />
      </>
    )
  }

  const renderButtons = () => {
    return (
      <>
        {!isCompact && <HiringScorecardSaveToDraftButton />}
        <HiringScorecardsSubmitButton
          noPopup={noPopup}
          submitLabel={submitLabel}
          onSubmit={onSubmit}
          onAfterSubmit={onAfterSubmit}
        />
      </>
    )
  }

  if (isCompact) {
    return (
      <>
        <Subheader>Scorecard details</Subheader>
        {renderScorecardDetails()}
        {renderPredefinedSections(<Subheader>Predefined sections</Subheader>)}
        <Subheader>Scorecard sections</Subheader>
        {renderScorecardSections()}
        {renderActions?.(renderButtons())}
      </>
    )
  }

  return (
    <>
      <PageBody maxWidth={MAX_WIDTH}>
        <AutoStepper>
          <NewStepperTitle title="Scorecard details" />
          {renderScorecardDetails()}
          {renderPredefinedSections(<NewStepperTitle title="Predefined sections" />)}

          <NewStepperTitle title="Scorecard sections" />
          {renderScorecardSections()}
        </AutoStepper>
      </PageBody>
      <PageActions maxWidth={MAX_WIDTH}>{renderButtons()}</PageActions>
    </>
  )
}

export default connect(InterviewScorecardTemplateForm)

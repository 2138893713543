import React, { useEffect } from 'react'
import { Avatar, Banner, BannerProps } from '@revolut/ui-kit'
import {
  AvailableInterviewerSlotRequestArguments,
  useFetchDynamicSlotsCount,
} from '@src/api/recruitment/interviewerScheduling'
import pluralize from 'pluralize'
import { SlotBannerSkeleton } from '@src/pages/Forms/Candidate/ScheduleSidebar/SlotBannerSkeleton'
import { useShowStatusPopup } from '@src/utils/useShowStatusPopup'
import { getMessageFromApiError } from '@src/store/notifications/actions'

interface Props
  extends BannerProps,
    Pick<
      AvailableInterviewerSlotRequestArguments,
      | 'roundId'
      | 'stageId'
      | 'interviewId'
      | 'interviewers'
      | 'interviewerGroups'
      | 'duration'
      | 'durationUnit'
      | 'isAdhoc'
      | 'daysCount'
    > {}

export const AvailableSlotsBanner = ({
  roundId,
  stageId,
  interviewers,
  interviewerGroups,
  duration,
  durationUnit,
  isAdhoc,
  daysCount,
  ...props
}: Props) => {
  const showStatusPopup = useShowStatusPopup()

  const { data, isLoading, error, isError } = useFetchDynamicSlotsCount({
    stageId,
    roundId,
    interviewers,
    interviewerGroups,
    duration,
    durationUnit,
    isAdhoc,
    daysCount,
  })

  useEffect(() => {
    if (error) {
      showStatusPopup({
        title: 'Cannot fetch count of slots',
        description: getMessageFromApiError(error),
        status: 'error',
      })
    }
  }, [error])

  return (
    <Banner {...props}>
      <Banner.Avatar>
        <Avatar useIcon="CalendarWeek" size={56} />
      </Banner.Avatar>
      <Banner.Content>
        <Banner.Title>Available slots</Banner.Title>
        {isLoading ? (
          <SlotBannerSkeleton />
        ) : isError ? (
          <Banner.Description>Couldn't fetch amount of slots</Banner.Description>
        ) : (
          <Banner.Description>
            There {pluralize('is', data?.count)} currently{' '}
            {pluralize('time slot', data?.count, true)} available for the date period and
            interviewers you have chosen. New slots will be added as they become
            available. Candidates will be able to choose the time that suits them best.
          </Banner.Description>
        )}
      </Banner.Content>
    </Banner>
  )
}

import { getDeliverySectionDefault } from '@src/api/recruitment/interviewFeedback'
import { skillRequestsNew } from '@src/api/skills'
import {
  InterviewScorecardSectionIdLevel,
  InterviewScorecardTemplateSectionInterface,
  InterviewScorecardType,
} from '@src/interfaces/interviewScorecardTemplates'
import { OptionInterface } from '@src/interfaces/selectors'

export const getPrefilledSection = async (
  skill: OptionInterface,
  scorecardType?: InterviewScorecardType,
): Promise<InterviewScorecardTemplateSectionInterface> => {
  const baseSection = {
    title: skill.name,
    section_type: InterviewScorecardSectionIdLevel,
    skills: [skill],
  }

  if (scorecardType === InterviewScorecardType.Delivery) {
    try {
      const resp = await getDeliverySectionDefault()

      return {
        ...baseSection,
        basic_level_questions: resp.data.basic_level_questions,
        intermediate_level_questions: resp.data.intermediate_level_questions,
        advanced_level_questions: resp.data.advanced_level_questions,
        expert_level_questions: resp.data.expert_level_questions,
      }
    } catch (e) {
      return baseSection
    }
  }

  try {
    const resp = await skillRequestsNew.get({ id: String(skill.id) })

    return {
      ...baseSection,
      basic_level_questions: resp.data.mastery_level_definition_basic,
      intermediate_level_questions: resp.data.mastery_level_definition_intermediate,
      advanced_level_questions: resp.data.mastery_level_definition_advanced,
      expert_level_questions: resp.data.mastery_level_definition_expert,
    }
  } catch (e) {
    return baseSection
  }
}

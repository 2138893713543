import React from 'react'
import { connect } from 'lape'
import { Statuses } from '@src/interfaces'
import { FilterByInterface } from '@src/interfaces/data'
import { KPIsCardTableProps } from '@src/pages/Forms/EmployeePerformanceLayout/components/KPIsCardTable'
import { EntityTypes } from '@src/constants/api'
import { useGetGoalContentTypeFilter } from '@src/features/Goals/useGoalFilters'
import { EmployeeGoalsCardTableContent } from '@src/pages/Forms/EmployeePerformanceLayout/components/EmployeeGoalsCardTable'
import { getCycleIdWithAssociatedGoals } from '@src/utils/reviewCycles'

export const TeamGoalsCardTable = connect(
  ({
    cycle,
    team,
    isPerformanceBanner,
    setGoalsTableCount,
    showBanner,
    isNewDesign,
    ...props
  }: KPIsCardTableProps) => {
    const { filter: contentTypeFilter } = useGetGoalContentTypeFilter(EntityTypes.team)

    if (!contentTypeFilter) {
      return null
    }

    const cycleId = cycle ? getCycleIdWithAssociatedGoals(cycle) : undefined
    const initialFilters: FilterByInterface[] = [
      ...(cycle && cycleId
        ? [
            {
              columnName: 'cycle__id',
              filters: [
                {
                  id: String(cycleId),
                  name: String(cycle.name),
                },
              ],
              nonResettable: true,
            },
          ]
        : []),
      {
        columnName: 'approval_status',
        nonResettable: true,
        filters: [
          { id: Statuses.pending, name: Statuses.pending },
          { id: Statuses.approved, name: Statuses.approved },
          { id: Statuses.requires_changes, name: Statuses.requires_changes },
        ],
      },
      contentTypeFilter,
      ...(team
        ? [
            {
              columnName: 'object_id',
              filters: [{ id: team.id, name: team.name }],
              nonResettable: true,
              nonInheritable: true,
            },
          ]
        : []),
    ]

    return (
      <EmployeeGoalsCardTableContent
        setGoalsTableCount={setGoalsTableCount}
        isNewDesign={isNewDesign}
        showBanner={showBanner}
        isPerformanceBanner={isPerformanceBanner}
        team={team}
        initialFilters={initialFilters}
        {...props}
      />
    )
  },
)

import {
  InterviewFeedbackSection,
  InterviewFeedbackSkillSectionInterface,
} from '@src/interfaces/interviewTool'
import { CardTypes, SectionType, SkillCardInterface } from '@src/interfaces/performance'

const getSkillSectionItems = (
  section: InterviewFeedbackSkillSectionInterface,
  levelKey: string,
) => {
  return section.sections?.find(({ level_key }) => levelKey === level_key)?.items
}

export const getCard = (
  section: InterviewFeedbackSkillSectionInterface,
): SkillCardInterface => {
  const basic = getSkillSectionItems(section, 'basic')
  const intermediate = getSkillSectionItems(section, 'intermediate')
  const advanced = getSkillSectionItems(section, 'advanced')
  const expert = getSkillSectionItems(section, 'expert')
  return {
    type: CardTypes.skillCard,
    sections: [
      {
        level_key: 'Basic',
        type: SectionType.CriteriasAssessment,
        items: basic,
      },
      {
        level_key: 'Intermediate',
        type: SectionType.CriteriasAssessment,
        items: intermediate,
      },
      {
        level_key: 'Advanced',
        type: SectionType.CriteriasAssessment,
        items: advanced,
      },
      {
        level_key: 'Expert',
        type: SectionType.CriteriasAssessment,
        items: expert,
      },
    ],
    name: section.title || '',
    rating_expectation: null,
  }
}

export const isInterviewFeedbackSkillSection = (
  section: InterviewFeedbackSection,
): section is InterviewFeedbackSkillSectionInterface =>
  section.internal_data_type === 'skill_interview_section'

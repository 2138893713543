import React, { useMemo } from 'react'
import {
  Ratings,
  ReviewCategory,
  SummarySkillCardJustificationInterface,
} from '@src/interfaces/performance'
import { connect } from 'lape'
import { Box, InputGroup, Text, VStack, Token, chain } from '@revolut/ui-kit'
import { get } from 'lodash'
import {
  CommonSectionProps,
  deliverablesRating,
  getRoundedRating,
  CommonGradeOption,
} from '@src/pages/Forms/EmployeePerformanceLayout/utils'
import { DeliverablesGrade } from '@src/pages/Forms/EmployeePerformanceLayout/Sections/Deliverables'
import { useParams } from 'react-router-dom'
import set from 'lodash/set'
import LapeNewTextArea from '@components/Inputs/LapeFields/LapeNewTextArea'
import { relationToString } from '@src/features/Scorecard/constants'
import { getViewGradesWithExpectations } from '@src/pages/Forms/EmployeePerformanceLayout/Cards/utils'
import { GradesMapInterface } from '@src/utils/grades'
import { useGetPerformanceSelector } from '@src/api/performance'
import { TableBodySkeleton } from '@src/components/Skeletons/TableBodySkeleton'
import { GoalsWidget } from './GoalsWidget'
import { NameIdInterface } from '@src/interfaces/requisitions'

interface Props extends CommonSectionProps {
  category?: ReviewCategory
  cycleId?: number
  checkpointNum?: number
  comments?: SummarySkillCardJustificationInterface[]
  gradesMap: GradesMapInterface
  team: NameIdInterface | undefined
}

// FYI It's called "Probation" but in reality it's also used in PIP
export const ProbationDeliverables = connect(
  ({
    reviewData,
    isViewMode = false,
    category,
    cycleId,
    checkpointNum,
    comments,
    gradesMap,
    team,
  }: Props) => {
    const { employeeId } = useParams<{ employeeId: string }>()

    const { data: cycles, isLoading } = useGetPerformanceSelector(employeeId, {
      exclude_adhoc: true,
    })

    const selectedCycle = useMemo(() => {
      // for some weird reason, although the employee cycles are with "E" one retunred in the probation_checkpoint.cycle_id is just a number, but should be with E
      return cycles?.find(cycle => cycle.id === `E-${cycleId}`)
    }, [cycles, cycleId, isLoading])

    if (!reviewData) {
      return null
    }

    const getFieldDetails = (option: CommonGradeOption) => {
      const ratingValue = get(
        reviewData,
        'calculated_deliverables_ratings.recommended_rating',
      )
      const isChecked =
        deliverablesRating.find(item => item.key === ratingValue)?.key === option.key

      return { isChecked, ratings: [] }
    }
    const getViewFieldDetails = (option: CommonGradeOption) => {
      const ratings =
        get(
          reviewData,
          'calculated_deliverables_ratings.recommended_rating.ratings',
        )?.filter((rating: Ratings) => {
          return getRoundedRating(rating.value) === option.key
        }) || []
      const isChecked = !!ratings.length

      return { isChecked, ratings }
    }

    const deliverablesRatingWithExp = getViewGradesWithExpectations(deliverablesRating)
    const filteredComments = comments?.filter(comment => comment.value)
    return (
      <VStack data-testid="deliverables-section">
        <InputGroup>
          {isLoading ? (
            <TableBodySkeleton rowHeight="medium" />
          ) : (
            <GoalsWidget
              team={team}
              selectedCycle={selectedCycle}
              employeeId={employeeId}
              isViewMode={isViewMode}
              category={category}
              checkpointNum={checkpointNum}
              gradesMap={gradesMap}
            />
          )}

          <InputGroup>
            {deliverablesRatingWithExp.map((option, optionIdx) => {
              const { isChecked, ratings } = isViewMode
                ? getViewFieldDetails(option)
                : getFieldDetails(option)
              return (
                <DeliverablesGrade
                  key={optionIdx}
                  isViewMode={isViewMode}
                  isChecked={isChecked}
                  ratings={ratings}
                  option={option}
                  onChange={() => {
                    set(
                      reviewData,
                      'calculated_deliverables_ratings.recommended_rating',
                      option.key,
                    )
                  }}
                  gradesMap={gradesMap}
                />
              )
            })}
          </InputGroup>
          {isViewMode ? (
            <VStack space="s-8">
              {filteredComments?.map(comment => (
                <Box
                  p="s-16"
                  key={comment.review.id}
                  border={`1px solid ${Token.color.greyTone10}`}
                  borderRadius={Token.radius.r16}
                >
                  <VStack space="s-8">
                    <Text variant="primary">
                      {chain(
                        comment.review.reviewer.full_name,
                        relationToString(comment.review.reviewer_relation, true),
                      )}
                    </Text>
                    <Text variant="secondary" style={{ wordBreak: 'break-word' }}>
                      {comment.value}
                    </Text>
                  </VStack>
                </Box>
              ))}
            </VStack>
          ) : (
            <LapeNewTextArea
              rows={2}
              label="Justification / evidence or specific examples"
              name="review_data.calculated_deliverables_ratings.recommended_rating_comment"
            />
          )}
        </InputGroup>
      </VStack>
    )
  },
)
